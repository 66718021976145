var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "stoneIndex",
    class: _vm.isMobile && 'wap'
  }, [[_c('div', {
    staticClass: "bg",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-bg.jpg`)})`
    }
  }), _vm.isShowTurboScreen ? _c('div', {
    staticClass: "turboBg"
  }, _vm._l(2, function (_) {
    return _c('span', {
      style: {
        backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-small-coin.png`)})`
      }
    });
  }), 0) : _vm._e()], _c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "top-main"
  }, [_c('div', {
    staticClass: "header",
    on: {
      "click": function ($event) {
        return _vm.onSkip(_vm.userData.user_group_id <= 0 ? 'join' : 'squad', _vm.userData.user_group_id);
      }
    }
  }, [_c('div', {
    staticClass: "icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-squad.png`)})`
    }
  }), _c('span', [_vm.userData.user_group_id ? [_vm._v(" " + _vm._s(_vm.userData.user_group_name) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang("JOIN SQUAD")) + " ")]], 2)])]), _c('div', {
    ref: "totalRef",
    staticClass: "total",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "icon",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/index-icon-small-coin.png`)})`
    }
  }), _c('div', {
    staticClass: "total__value",
    class: !_vm.isMobile && 'pc'
  }, [!_vm.isIos ? [_c('CountUp', {
    attrs: {
      "num": _vm.userData.coin
    }
  })] : [_vm._v(_vm._s(_vm._f("toThousands")(_vm.userData.coin)))]], 2)]), _c('div', {
    staticClass: "sub",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  })]), _c('div', {
    staticClass: "center"
  }, [_c('div', {
    staticClass: "coin",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.handleClick($event);
      },
      "click": function ($event) {
        !_vm.isMobile && _vm.handleClick($event);
      }
    }
  }, [_c('div', {
    ref: "coinRef",
    staticClass: "coin-main",
    class: !_vm.isMobile && 'pc'
  }, [_c('div', {
    staticClass: "stone"
  }, [_vm._m(0), _c('div', {
    staticClass: "stone-main"
  }, [_c('img', {
    ref: "stoneBGRef",
    staticClass: "stone_bg",
    attrs: {
      "src": require(`@images/${_vm.theme}/stone_bg.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "stone_list"
  }, [_vm.stoneTime === 1 ? _c('img', {
    staticClass: "stone_item",
    attrs: {
      "src": require(`@images/${_vm.theme}/stone_s_1.png`),
      "alt": ""
    }
  }) : _vm.stoneTime === 2 ? _c('img', {
    staticClass: "stone_item",
    attrs: {
      "src": require(`@images/${_vm.theme}/stone_s_2.png`),
      "alt": ""
    }
  }) : _vm.stoneTime === 3 ? _c('img', {
    staticClass: "stone_item",
    attrs: {
      "src": require(`@images/${_vm.theme}/stone_s_3.png`),
      "alt": ""
    }
  }) : _vm.stoneTime === 4 ? _c('img', {
    staticClass: "stone_item",
    attrs: {
      "src": require(`@images/${_vm.theme}/stone_s_4.png`),
      "alt": ""
    }
  }) : _vm._e()])])])])])]), _c('div', {
    ref: "coinListRef",
    staticClass: "coin_list"
  }), _c('div', {
    staticClass: "bottomX",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "progress-main"
  }, [_c('div', {
    staticClass: "progress__text"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/sd.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.userData.power)), _c('i', [_vm._v("/" + _vm._s(_vm.userData.power_max))])])]), _c('div', {
    staticClass: "bar"
  }, [_c('div', {
    staticClass: "bar-main",
    style: {
      left: -100 + Number(_vm.progressBar) + '%'
    }
  })])]), _c('div', {
    staticClass: "btn-boosts",
    on: {
      "click": function ($event) {
        return _vm.onSkip('boost');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/btn-icon-boost.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Boosts")) + " ")])]), _c('m-menu')], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowTurboIcon,
      expression: "isShowTurboIcon"
    }],
    ref: "turboRef",
    staticClass: "turbo",
    on: {
      "touchstart": function ($event) {
        _vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      },
      "click": function ($event) {
        $event.stopPropagation();
        !_vm.isMobile && _vm.isShowTurboIcon && _vm.onShowTurboScreen();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/turbo.png`),
      "alt": ""
    }
  })]), _c('pop-robot', {
    attrs: {
      "show": _vm.popRobotShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRobotShow = $event;
      }
    }
  }), _c('pop-login-reward', {
    attrs: {
      "show": _vm.popLoginRewardShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popLoginRewardShow = $event;
      }
    }
  })], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "stoneCanvas"
  }, [_c('canvas', {
    attrs: {
      "id": "hoeCanvas"
    }
  })]);
}];
export { render, staticRenderFns };