import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const mineVuex = createNamespacedHelpers("mine");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
export default {
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  watch: {
    taskInfo: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isFlag: true,
      loadingText: "..."
    };
  },
  methods: {
    ...mineVuex.mapActions(["mineUpgrade", "mine"]),
    async onSubmit() {
      if (!this.isFlag) return;
      this.isFlag = false;
      let t = setInterval(() => {
        if (this.loadingText.length < 3) {
          this.loadingText += ".";
        } else {
          this.loadingText = ".";
        }
      }, 500);
      await this.mineUpgrade([this.taskInfo.id, async rs => {
        this.isFlag = true;
        clearInterval(t);
        //this.$parent.taskInfo = rs.data.task_info;
        this.$parent.popEnergyShow = false;
        if (rs.lucky_coin && rs.lucky_coin > 0) {
          this.$parent.doSpecialsMine();
          this.$parent.awardCoin = rs.lucky_coin;
          this.$parent.popAwardShow = true;
        }
        this.$parent.refreshData();
      }, () => {
        this.isFlag = true;
        clearInterval(t);
        this.$parent.popEnergyShow = false;
      }]);
    }
  }
};