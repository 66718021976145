var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "task-detail page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "task-detail-main"
  }, [_c('div', {
    staticClass: "top-box"
  }, [_c('div', {
    staticClass: "top-box-bg",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }), _c('div', {
    staticClass: "top-title"
  }, [_c('div', [_vm._v("Submit Code")]), _c('div', {
    staticClass: "top-coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("nFormatter")(_vm.award)))])]), _c('div', {
    staticClass: "top-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.code,
      expression: "code"
    }],
    attrs: {
      "placeholder": "Enter code",
      "maxlength": "20"
    },
    domProps: {
      "value": _vm.code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.code = $event.target.value;
      }
    }
  }), _c('button', {
    style: !_vm.lock ? {
      background: _vm.themeMainColor(_vm.theme).main
    } : 'background: gray',
    on: {
      "click": _vm.checkCode
    }
  }, [!_vm.lock ? _c('span', [_vm._v(_vm._s(_vm.$lang("Submit")))]) : _c('span', [_vm._v(_vm._s(_vm.loadingText))])])]), _vm.error ? _c('div', {
    staticClass: "error-msg"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e()]), _vm._m(0), _c('div', {
    staticClass: "detail-title"
  }, [_vm._v(" How to get the code? ")]), _vm._m(1), _c('div', {
    staticClass: "detail-btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.jumpTo
    }
  }, [_c('div', [_vm._v(" Biomatrix ")]), _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1), _vm._m(2), _c('div', {
    staticClass: "detail-step-img"
  }, [_c('img', {
    staticClass: "step-img",
    attrs: {
      "src": require(`@images/${_vm.theme}/task-detail-step-1.jpg`),
      "alt": ""
    }
  })]), _vm._m(3), _c('div', {
    staticClass: "detail-step-img"
  }, [_c('img', {
    staticClass: "arrow-img arrow-img-1",
    attrs: {
      "src": require(`@images/${_vm.theme}/task-detail-arrow.png`),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "step-img",
    attrs: {
      "src": require(`@images/${_vm.theme}/task-detail-step-2.jpg`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "detail-step-img"
  }, [_c('img', {
    staticClass: "arrow-img arrow-img-2",
    attrs: {
      "src": require(`@images/${_vm.theme}/task-detail-arrow2.png`),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "step-img",
    attrs: {
      "src": require(`@images/${_vm.theme}/task-detail-step-3.jpg`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "detail-step-img"
  }, [_c('img', {
    staticClass: "arrow-img arrow-img-3",
    attrs: {
      "src": require(`@images/${_vm.theme}/task-detail-arrow.png`),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "step-img",
    attrs: {
      "src": require(`@images/${_vm.theme}/task-detail-step-4.jpg`),
      "alt": ""
    }
  })])]), _c('pop-tip-task-detail', {
    attrs: {
      "show": _vm.popTipShow,
      "award": _vm.award
    },
    on: {
      "update:show": function ($event) {
        _vm.popTipShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "coin-box"
  }, [_c('img', {
    attrs: {
      "src": "https://pbs.twimg.com/profile_images/1775753167877382144/8f_-3Jvg_400x400.jpg"
    }
  }), _c('div', {
    staticClass: "coin-box-info"
  }, [_c('div', {
    staticClass: "coin-box-title"
  }, [_vm._v("Biomatrix")]), _c('div', {
    staticClass: "coin-box-des"
  }, [_vm._v("AI Tokens for All. Redefining financial inclusion and accessibility for all through UBI and PoY AI Tokens on BioMatrix.")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "detail-step"
  }, [_c('div', {
    staticClass: "step-left"
  }, [_vm._v("Step 1")]), _c('div', {
    staticClass: "step-right"
  }, [_vm._v("Visit the Biomainix registration page")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "detail-step"
  }, [_c('div', {
    staticClass: "step-left"
  }, [_vm._v("Step 2")]), _c('div', {
    staticClass: "step-right"
  }, [_vm._v("Scan face to reglster")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "detail-step"
  }, [_c('div', {
    staticClass: "step-left"
  }, [_vm._v("Step 3")]), _c('div', {
    staticClass: "step-right"
  }, [_vm._v("Copy code")])]);
}];
export { render, staticRenderFns };