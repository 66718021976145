var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "earn page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "earn-main"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "head-img",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/earn-head-icon-gift.png`)})`
    }
  }), _c('div', {
    staticClass: "head__title"
  }, [_vm._v(_vm._s(_vm.$lang("More tasks, More rewards")))])]), _c('div', {
    staticClass: "invite"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Official")) + " "), _vm.taskNum > 0 ? _c('em', [_vm._v(_vm._s(_vm.taskNum))]) : _vm._e()]), _c('div', {
    staticClass: "invite-list"
  }, [_vm.setting.socials.discord ? _c('div', {
    staticClass: "invite-item",
    class: _vm.userData.joined_discord == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('discord', _vm.setting.socials.discord);
      }
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-discord"
    }
  })], 1), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Join discrod")))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.discord)) + " ")])]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.userData.joined_discord == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.joined_discord == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)]) : _vm._e(), _vm.setting.socials.channel ? _c('div', {
    staticClass: "invite-item",
    class: _vm.userData.joined_channel == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('channel', _vm.setting.socials.channel);
      }
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Join channel")))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.channel)) + " ")])]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.userData.joined_channel == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.joined_channel == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)]) : _vm._e(), _vm.setting.socials.twitter ? _c('div', {
    staticClass: "invite-item",
    class: _vm.userData.joined_twitter == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('twitter', _vm.setting.socials.twitter);
      }
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-lfg"
    }
  })], 1), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Follow us on X")))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.twitter)) + " ")])]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.userData.joined_twitter == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.joined_twitter == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)]) : _vm._e(), _vm.setting.socials.telegram ? _c('div', {
    staticClass: "invite-item",
    class: _vm.userData.joined_telegram == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onJoin('telegram', _vm.setting.socials.telegram);
      }
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    }
  })], 1), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Join group")))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.joined.telegram)) + " ")])]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.userData.joined_telegram == 0 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.joined_telegram == 1 ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)]) : _vm._e(), _c('div', {
    staticClass: "invite-item",
    class: _vm.userData.bind_wallet && 'active',
    on: {
      "click": _vm.onBindWallet
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tron.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Connect wallet")))]), _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.setting.reward.bindWallet)) + " ")])]), _c('div', {
    staticClass: "invite-arrow"
  }, [!_vm.userData.bind_wallet ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.userData.bind_wallet ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)])])]), _c('div', {
    staticClass: "invite"
  }, [_c('div', {
    staticClass: "invite__tabs"
  }, [_c('div', {
    staticClass: "invite__tab",
    class: _vm.selectTab == 0 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onTab(0);
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Extra Tasks")) + " "), _vm.extraTaskNum > 0 ? _c('em', [_vm._v(_vm._s(_vm.extraTaskNum))]) : _vm._e()]), _c('div', {
    staticClass: "invite__tab",
    class: _vm.selectTab == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onTab(1);
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Daily")) + " "), _c('em', {
    staticStyle: {
      "min-width": "auto",
      "width": "10px",
      "height": "10px"
    }
  })])]), _vm.selectTab == 0 ? _c('div', {
    staticClass: "invite-list"
  }, _vm._l(_vm.taskList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "invite-item",
      class: item.completed == 1 && 'active',
      on: {
        "click": function ($event) {
          return _vm.onExtralJoin(item);
        }
      }
    }, [_c('div', {
      staticClass: "invite-icon"
    }, [item.icon ? [_c('img', {
      attrs: {
        "src": item.icon
      }
    })] : [item.type == 'discord' ? _c('svg-icon', {
      attrs: {
        "name": "icon-discord"
      }
    }) : _vm._e(), item.type == 'telegram' || item.type == 'channel' ? _c('svg-icon', {
      attrs: {
        "name": "icon-telegram"
      }
    }) : _vm._e(), item.type == 'twitter' ? _c('svg-icon', {
      attrs: {
        "name": "icon-lfg"
      }
    }) : _vm._e()]], 2), _c('div', {
      staticClass: "invite-info"
    }, [_c('div', {
      staticClass: "invite__title"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "invite__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.reward)) + " ")])]), _c('div', {
      staticClass: "invite-arrow"
    }, [item.completed == 0 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })] : item.completed == 1 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })] : _vm._e()], 2)]);
  }), 0) : _vm._e(), _vm.selectTab == 1 ? _c('div', {
    staticClass: "invite-list"
  }, [_c('div', {
    staticClass: "invite-item",
    class: _vm.summary && _vm.summary.check_in && _vm.summary.check_in.today_completed && 'active',
    on: {
      "click": function ($event) {
        return _vm.onSkip('sign');
      }
    }
  }, [_c('div', {
    staticClass: "invite-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/sign-calendar.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "invite-info"
  }, [_c('div', {
    staticClass: "invite__title"
  }, [_vm._v(_vm._s(_vm.$lang("Daily Login")))]), _vm.summary && _vm.summary.check_in ? _c('div', {
    staticClass: "invite__num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.summary.check_in.total_coins)) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "invite-arrow"
  }, [_vm.summary && _vm.summary.check_in && !_vm.summary.check_in.today_completed ? [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })] : _vm.summary && _vm.summary.check_in && _vm.summary.check_in.today_completed ? [_c('svg-icon', {
    attrs: {
      "name": "icon-ok"
    }
  })] : _vm._e()], 2)]), _vm._l(_vm.advList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "invite-item",
      class: item.completed == 1 && 'active',
      on: {
        "click": function ($event) {
          item.completed == 0 && _vm.onExtralJoin(item, 1);
        }
      }
    }, [_c('div', {
      staticClass: "invite-icon"
    }, [item.icon ? [_c('img', {
      attrs: {
        "src": item.icon
      }
    })] : [item.type == 'discord' ? _c('svg-icon', {
      attrs: {
        "name": "icon-discord"
      }
    }) : _vm._e(), item.type == 'telegram' || item.type == 'channel' ? _c('svg-icon', {
      attrs: {
        "name": "icon-telegram"
      }
    }) : _vm._e(), item.type == 'twitter' ? _c('svg-icon', {
      attrs: {
        "name": "icon-lfg"
      }
    }) : _vm._e()]], 2), _c('div', {
      staticClass: "invite-info"
    }, [_c('div', {
      staticClass: "invite__title"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "invite__num"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(_vm._f("toThousands")(item.reward)) + " ")])]), item.daily_limit_count > 0 ? _c('div', {
      staticClass: "invite-com-count"
    }, [_vm._v(" " + _vm._s(item.daily_completion_count) + "/" + _vm._s(item.daily_limit_count) + " ")]) : _vm._e(), item.countdown > 0 ? _c('div', {
      staticClass: "invite-time"
    }, [_vm._v(" " + _vm._s(_vm._f("formatHour")(item.countdown)) + " ")]) : _vm._e(), _c('div', {
      staticClass: "invite-arrow"
    }, [item.completed == 0 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })] : item.completed == 1 && item.cyclical == 0 ? [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })] : _vm._e()], 2)]);
  })], 2) : _vm._e()])]), _c('pop-up-gold', {
    attrs: {
      "show": _vm.popUpGoldShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popUpGoldShow = $event;
      }
    }
  }), _c('pop-coin-hero', {
    attrs: {
      "show": _vm.popCoinHeroShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popCoinHeroShow = $event;
      }
    }
  }), _c('pop-bind-wallet', {
    attrs: {
      "show": _vm.popBindWalletShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popBindWalletShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };