import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const mineVuex = createNamespacedHelpers("mine");
export default {
  name: "sign",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...mineVuex.mapState(["hourEearnings"])
  },
  data() {
    return {
      popAirdropTipShow: false,
      popBindWalletShow: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popAirdropTipShow = false;
    this.popBindWalletShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    if (!this.hourEearnings) {
      await this.getHourEarnings();
    }
  },
  watch: {
    "userData": {
      handler() {},
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mineVuex.mapActions(["getHourEarnings"]),
    showTip() {
      this.popAirdropTipShow = true;
    },
    onBindWallet() {
      this.popBindWalletShow = true;
    },
    async onJoin(type, url, extra) {
      console.log("url=====", url);
      if (type == 'telegram' || type == 'channel') {
        this.WebApp.openTelegramLink(url);
      } else {
        this.WebApp.openLink(url);
      }
      if (!this.userData[`joined_${type}`]) {
        this.doCheckJoin(type);
      }
    }
  }
};