import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "airdrop page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "airdrop-main"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "head__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop.png`)
    }
  })]), _c('div', {
    staticClass: "head__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Airdrop & Freemint")) + " "), _c('i', {
    on: {
      "click": _vm.showTip
    }
  }, [_vm._v("!")])]), _c('div', {
    staticClass: "head__desc"
  }, [_vm._v(" Airdrop and Freemint opportunities for outstanding " + _vm._s(_vm.setting.coin) + " players ")])]), _c('div', {
    staticClass: "airdrop-content"
  }, [_c('div', {
    staticClass: "content__top"
  }, [_c('div', {
    staticClass: "content__top__left",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/frens');
      }
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Invited")]), _c('div', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.userData.extra_invite_count))])]), _c('div', {
    staticClass: "v-line"
  }), _c('div', {
    staticClass: "content__top__right",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/mine');
      }
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Profit per hour")]), _c('div', {
    staticClass: "num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`)
    }
  }), _vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.hourEearnings, 2)) + " ")])])]), _c('div', {
    staticClass: "content__bottom"
  }, [_vm.setting.socials.channel ? _c('div', {
    staticClass: "content__bottom__left",
    class: _vm.userData.joined_channel == 1 && 'checked',
    on: {
      "click": function ($event) {
        return _vm.onJoin('channel', _vm.setting.socials.channel);
      }
    }
  }, [_vm.userData.joined_channel == 1 ? _c('div', {
    staticClass: "finish"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-ok.png`)
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Join the channel")))]), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-channel.png`)
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "content__bottom__right",
    class: _vm.userData.bind_wallet && 'checked',
    on: {
      "click": _vm.onBindWallet
    }
  }, [_vm.userData.bind_wallet ? _c('div', {
    staticClass: "finish"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-ok.png`)
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Connect Ton wallet")))]), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-wallet.png`)
    }
  })])])])]), _c('div', {
    staticClass: "airdrop__bottom"
  }, [_c('div', {
    staticClass: "airdrop__bottom__left",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/airdrop-left-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "airdrop__bottom_img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-big.png`)
    }
  })]), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Airdrop")))]), _c('div', {
    staticClass: "btn"
  }, [_vm._v(_vm._s(_vm.$lang("Coming Soon")))])]), _c('div', {
    staticClass: "airdrop__bottom__right",
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/airdrop-right-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "bottom_img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-freemint.png`)
    }
  })]), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Freemint")))]), _c('div', {
    staticClass: "btn"
  }, [_vm._v(_vm._s(_vm.$lang("Coming Soon")))])])])]), _c('pop-airdrop-tip', {
    attrs: {
      "show": _vm.popAirdropTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropTipShow = $event;
      }
    }
  }), _c('pop-bind-wallet', {
    attrs: {
      "show": _vm.popBindWalletShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popBindWalletShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };