var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.taskInfo ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": _vm.taskInfo.icon,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(_vm._s(_vm.taskInfo.name))]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.taskInfo.description) + " ")]), _c('div', {
    staticClass: "energy__threetitle"
  }, [_c('div', [_vm._v(" Profit per hour "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v("+" + _vm._s(_vm.taskInfo.upgrade_inc_earnings))])])]), _c('div', {
    staticClass: "energy-coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.taskInfo.upgrade_cost)))])]), _c('div', {
    staticClass: "btn-level",
    style: _vm.isFlag ? {
      background: _vm.themeMainColor(_vm.theme).main
    } : 'color: #fff; background: gray; line-height:35px',
    attrs: {
      "enabled": _vm.isFlag
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm.isFlag ? _c('span', [_vm._v(_vm._s(_vm.$lang("Go ahead")))]) : _c('span', [_vm._v(_vm._s(_vm.loadingText))])])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };