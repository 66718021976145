import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "airdrop page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "airdrop-main"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "head__top"
  }, [_c('div', {
    staticClass: "head__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop.png`)
    }
  })]), _c('div', {
    staticClass: "head__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Airdrop")) + " "), _c('i', {
    on: {
      "click": _vm.showTip
    }
  }, [_vm._v("!")])])]), _vm._m(0)]), _c('div', {
    staticClass: "airdrop-content"
  }, [_c('div', {
    staticClass: "content__top"
  }, [_c('div', {
    staticClass: "content__top__left",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/frens');
      }
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Invited")]), _c('div', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.userData.extra_invite_count))])]), _c('div', {
    staticClass: "v-line"
  }), _c('div', {
    staticClass: "content__top__right",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/mine');
      }
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Profit per hour")]), _c('div', {
    staticClass: "num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`)
    }
  }), _vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.hourEearnings, 2)) + " ")])])]), _c('div', {
    staticClass: "content__bottom"
  }, [_vm.setting.socials.channel ? _c('div', {
    staticClass: "content__bottom__left",
    class: _vm.userData.joined_channel == 1 && 'checked',
    on: {
      "click": function ($event) {
        return _vm.onJoin('channel', _vm.setting.socials.channel);
      }
    }
  }, [_vm.userData.joined_channel == 1 ? _c('div', {
    staticClass: "finish"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-ok.png`)
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-channel.png`)
    }
  })]), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Join the channel")))])]) : _vm._e(), _c('div', {
    staticClass: "content__bottom__right",
    class: _vm.userData.bind_wallet && 'checked',
    on: {
      "click": _vm.onBindWallet
    }
  }, [_vm.userData.bind_wallet ? _c('div', {
    staticClass: "finish"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-ok.png`)
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-wallet.png`)
    }
  })]), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang("Connect Ton wallet")))])])])]), _c('div', {
    staticClass: "airdrop__bottom"
  }, [_vm.assetsInfo.symbol || '' ? _c('div', {
    staticClass: "airdrop__bottom__top"
  }, [_c('div', {
    staticClass: "balance-info"
  }, [_c('div', {
    staticClass: "balance__type",
    on: {
      "click": _vm.onSelectToken
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.assetsInfo.icon
    }
  }), _c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1), _c('div', {
    staticClass: "balance__num"
  }, [_vm._v(_vm._s(_vm._f("nFormatter")(_vm.assetsInfo.balance, 2)))])]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-d",
    on: {
      "click": function ($event) {
        return _vm.onWithdraw();
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Withdraw")))]), _c('div', {
    staticClass: "tip"
  }, [_vm._v("Coming Soon")])])]) : _vm._e(), _vm._l(_vm.airdropList, function (item, index) {
    return _c('div', {
      staticClass: "airdrop__bottom__bottom",
      on: {
        "click": function ($event) {
          return _vm.$router.push(`/airdropReward?id=${item.id}`);
        }
      }
    }, [item.background ? _c('div', {
      staticClass: "bg"
    }, [_c('img', {
      attrs: {
        "src": item.background
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "box"
    }, [item.icon ? _c('div', {
      staticClass: "icon"
    }, [_c('img', {
      attrs: {
        "src": item.icon
      }
    })]) : _vm._e(), item.title ? _c('div', {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.title))]) : _vm._e(), item.icon || item.title ? _c('div', {
      staticClass: "op"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })], 1) : _vm._e()])]);
  })], 2)]), _c('pop-airdrop-tip', {
    attrs: {
      "show": _vm.popAirdropTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropTipShow = $event;
      }
    }
  }), _c('pop-bind-wallet', {
    attrs: {
      "show": _vm.popBindWalletShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popBindWalletShow = $event;
      }
    }
  }), _c('pop-select-token', {
    attrs: {
      "show": _vm.popSelectTokenShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popSelectTokenShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head__desc"
  }, [_vm._v(" Excellent players have more opportunities to"), _c('br'), _vm._v("participate inairdrops ")]);
}];
export { render, staticRenderFns };