import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "taskDetail",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      taskId: "",
      code: "",
      error: "",
      lock: false,
      loadingText: '...',
      popTipShow: false,
      award: 50000
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    this.taskId = this.$route.query.id;
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...taskVuex.mapMutations(["setTaskComplete"]),
    ...taskVuex.mapActions(["doTask"]),
    ...userVuex.mapMutations(['setUserData']),
    checkCode() {
      this.error = '';
      if (this.lock) return;
      if (!this.taskId) {
        this.error = 'Parameter error, please return and retry';
        return;
      }
      if (!this.code) {
        this.error = 'Please enter code';
        return;
      }
      this.lock = true;
      let t = setInterval(() => {
        if (this.loadingText.length < 3) {
          this.loadingText += ".";
        } else {
          this.loadingText = ".";
        }
      }, 500);
      this.doTask([this.taskId, this.code, rs => {
        //this.$toasted.success(rs.message);
        clearInterval(t);
        this.lock = false;
        this.code = '';
        this.popTipShow = true;
        this.setTaskComplete({
          id: this.taskId
        });
        let newUserData = {
          ...this.userData,
          ...(rs.data && rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.loadTaskCount();
      }, rs => {
        this.error = rs && rs.message;
        clearInterval(t);
        this.lock = false;
      }]);
    },
    jumpTo() {
      this.WebApp.openLink("https://app.biomatrix.ai/refer?code=HVGZ2R");
    }
  }
};