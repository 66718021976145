import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const airdropVuex = createNamespacedHelpers("airdrop");
const assetsVuex = createNamespacedHelpers("assets");
import vailcode from "@utils/errcode";
import { nFormatter } from "@utils/index";
export default {
  name: "airdropReward",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...airdropVuex.mapState(["airdropInfo", "airdropInvitation"]),
    ...assetsVuex.mapState(["assetsInfo"])
  },
  data() {
    return {
      isFlag: true
    };
  },
  beforeRouteLeave(to, from, next) {
    this.isFlag = true;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {
    "airdropInfo": {
      handler(newVal, oldVal) {
        console.log(11111);
        if (this.assetsInfo.symbol == this.airdropInfo.token_assets.symbol) {
          this.setAssetsInfo(this.airdropInfo.token_assets);
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    let id = this.$route.query.id || 0;
    if (id > 0) {
      await this.getAirdropInfo(id);
      await this.getAirdropInvitationList([id, 100, 1]);
    }
  },
  methods: {
    ...airdropVuex.mapMutations(["setAirdropInfo"]),
    ...airdropVuex.mapActions(["getAirdropInfo", "getAirdropInvitationList"]),
    ...assetsVuex.mapMutations(["setAssetsInfo"]),
    onShare() {
      this.WebApp.openTelegramLink(`https://t.me/share/url?text=200,000%20USDT%20airdrop%20has%20started,%20come%20to%20Tapcoins%20to%20claim%20it&url=https://t.me/${this.setting.botname}/app?startapp=ref_${this.userData.ref_code}`);
    },
    async onRecive(airdropId) {
      if (!this.isFlag) return;
      this.isFlag = false;
      let rs = await this.$http.post("/airdrop/receive", {
        airdropId
      });
      vailcode(rs, async () => {
        await this.setAirdropInfo(rs.data);
      });
      this.isFlag = true;
    }
  }
};