import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    assetsList: [],
    assetsInfo: {}
  },
  mutations: {
    setAssetsList: (state, payload) => {
      state.assetsList = payload;
    },
    setAssetsInfo: (state, payload) => {
        state.assetsInfo = payload;
    },
  },
  actions: {
    getAssetsList:async ({ state, commit }) => {
        const rs = await Vue.prototype.$http.post("/user/assets/list",{});
        vailcode(rs, () => {
          commit("setAssetsList", rs.data);
          if((state.assetsInfo.symbol || ''))
          {
            rs.data.map(item=>{
                if(item.symbol == state.assetsInfo.symbol){
                    commit("setAssetsInfo", item);
                }
            })
          }else{
            commit("setAssetsInfo", rs.data[0] || {});
          }
        });
    },
    getAssetsInfo:async ({ state, commit },[symbol,callback,failcallback]) => {
        const rs = await Vue.prototype.$http.post("/user/assets/info",{symbol});
        vailcode(rs, () => {
            commit("setAssetsInfo", rs.data);
            typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        });
    }
  },
  getters: {},
};
